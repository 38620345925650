import React, { forwardRef, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import NextPage from '../../components/navigation/next/next-container';
import PreviousPage from '../../components/navigation/previous/previous-container';
import SEO from '../../components/seo/seo';

import useVisibleScrollBar from '../../utils/use-visible-scrollbar';

import './page-13.css';

const PageThirteen = forwardRef((props, ref) => (
  <>
    <SEO title="Page 13" />
    <main className="page page13">
      <div className="page__image-container">
        <div className="page__image-border-container">
          <StaticImage
            alt="Stacks of goobles and fuzzerbees"
            aspectRatio={3500 / 3885}
            className="page__image"
            height={750}
            layout="constrained"
            loading="eager"
            objectFit="contain"
            placeholder="tracedSVG"
            src="../../images/page-13.png"
          />
        </div>
      </div>
      <div
        className="page__text-container"
        ref={ref}
      >
        <div className="page__text">
          <p>
            There were stacks of finished goods marked
            {' '}
            <span className="page__text-fancy">
              goobles, jigglers, batwams and fuzzerbees
            </span>
            .
          </p>
          <p>
            Amanda could now see that Jimbugs and the others wore goobles on their feet.
            Jigglers and batwams were like trousers and shirts. Fuzzerbees covered their heads.
          </p>
          <p>
            <strong>
              &ldquo;So this is where the socks all go!&rdquo;
            </strong>
          </p>
        </div>
      </div>
      <PreviousPage to="/page-12" />
      <NextPage to="/page-14" />
    </main>
  </>
));

const PageThirteenContainer = () => {
  const textRef = useRef();
  useVisibleScrollBar(textRef, 'page__text-container-scroll-indicator');

  return <PageThirteen ref={textRef} />;
};

export default PageThirteenContainer;
